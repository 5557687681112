import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { CountdownComponent } from 'ngx-countdown';
import { AuthService } from '../_services/auth.service';
import { DataService } from '../_services/data.service';
import { ActivatedRoute, Router} from "@angular/router";
import Swal from 'sweetalert2';


@Component({
  selector: 'app-room',
  templateUrl: './room.component.html',
  styleUrls: ['./room.component.css']
})

export class RoomComponent implements OnInit {

  constructor(public auth: AuthService, public dataService: DataService, private route: ActivatedRoute, private router: Router) { }
  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;

  activeUsers = [{}];
  currentUser;
  avatarURL;
  me;
  usersListener: any;
  questionsListener: any;
  nextQuestionListener: any;
  qMasterListener: any;
  questions: any;
  nextQuestion;
  showNextButton = 'visible';
  disableNextButton = true;
  qMasterSet = false;
  responded = false;
  roomName;
  currentQuestion = 0;

  config= {
    leftTime: 3
  }

  ngAfterContentInit() :void {
    if (localStorage.getItem('currentUser')) {
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      if (this.roomName)
         this.dataService.joinRoom(this.roomName, this.currentUser.uid);
      this.auth.getCurrentUser(this.currentUser.uid).subscribe(user=>{
        this.currentUser.data = user.payload.data();
      })
    }
  
    if (this.roomName) {
      this.usersListener = this.auth.getActiveUsersInRoom(this.roomName).subscribe(users=>{
        this.activeUsers = users.map(a => {
          const data = a.payload.doc.data();
          const uid = a.payload.doc.id;
          return { uid, ...(data as Record<string, unknown>) };
        });


      })
  
      this.questionsListener = this.dataService.getQuestions().subscribe(qs=>{
        this.questions = qs.docs.map(a=>{
          const data = a.data();
          const id = a.id;
          return { id, ...(data as Record<string, unknown>)};
        })
        console.log(this.questions);
      })
  
      this.nextQuestionListener = this.dataService.getNextQuestion(this.roomName).subscribe(qs=>{
        this.nextQuestion = qs.payload.data();
        console.log(this.roomName, this.nextQuestion);
        if (this.countdown)
          this.countdown.restart();
        
          if (this.nextQuestion) {
            this.currentQuestion = this.nextQuestion.counter != undefined? this.nextQuestion.counter:0;
            this.showNextButton = 'hidden';
            this.disableNextButton = true;
            this.responded = false;    
          }
      })
  
      this.qMasterListener = this.dataService.getQuizMaster(this.roomName).subscribe(dl=>{
        this.qMasterSet = (dl.length > 0 ? true:false);
      })
    }

  }
  ngOnInit() :void {
    this.route.queryParams.subscribe(params => {
     if (!params.roomName) {
      this.router.navigate(['/home'])
     } else  {
      this.roomName = params.roomName;
     }
    });



  }
  
  handleEvent($event){
    switch ($event.action) {
      case 'done':
        this.showNextButton = 'visible';
        this.disableNextButton = false;
        break;
      case 'start':
        this.showNextButton = 'hidden';
        this.disableNextButton = true;
        break;
    } 
  }

  showNextQuestion(){
    // let nextQuestion = this.questions.sort(() => Math.random() - 0.5).slice(0, 1)[0];
    this.currentQuestion++;
    let nextQuestion = this.questions[this.currentQuestion];
    this.dataService.setNextQuestionForEveryone(this.roomName, nextQuestion, this.currentQuestion).then(res=>{
    });

  }

  async setQuizMaster() {
      this.dataService.setQuizMaster(this.currentUser.uid, 15);
      this.showNextQuestion();
  }

  response(val) {
    this.dataService.setResponse(this.currentUser, val);
    this.responded = true;
  }

  async resetGame() {
    Swal.showLoading();
    Swal.fire({
      title: 'Resetting Scores',
      showConfirmButton: false,
      allowOutsideClick: false
    })
    await this.dataService.resetGame(this.roomName);
    Swal.hideLoading();
    Swal.close();
  }

  @HostListener('window:beforeunload')
  async ngOnDestroy(){
    if (this.usersListener)
      this.usersListener.unsubscribe();
      
    if (this.questionsListener)
      this.questionsListener.unsubscribe();
    
    if ((this.nextQuestionListener))
      this.nextQuestionListener.unsubscribe();
    
    if (this.currentUser.data)
      this.auth.doLogout();
  }
}
