<!-- fixed-top-->
<nav
  class="top-header header-navbar navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow navbar-static-top navbar-light navbar-brand-center"
  id="top-header" [ngClass]="selectedHeaderNavBarClass">
  <div class="navbar-wrapper">
    <div id="navbar-header" class="navbar-header">
      <ul class="nav navbar-nav flex-row">
 
        <li class="nav-item mt-2">
            <h3 class="brand-text">{{_themeSettingsConfig.brand.brand_name}}</h3>
          </li>

      </ul>
    </div>
    <div class="navbar-container content">
      <div class="collapse navbar-collapse show" id="navbar-mobile">
        <ul class="nav navbar-nav mr-auto float-left">
          <li class="nav-item d-none d-md-block"></li>
        </ul>
        <ul class="nav navbar-nav float-right" *ngIf="currentUser && currentUser.data" >
          <li class="dropdown-user nav-item" ngbDropdown>
            <a class="nav-link dropdown-user-link" ngbDropdownToggle>
              <span *ngIf="currentUser.data.nickName"
                class="mr-1 user-name text-bold-700">{{currentUser.data.nickName}}</span>
              <span class="avatar avatar-online">
                <img *ngIf="currentUser.data.photoURL" src="{{currentUser.data.photoURL}}" alt="avatar">
                <!-- <img *ngIf="!currentUser.photoURL" src="../../../../assets/images/portrait/small/avatar-s-28.jpeg" -->
                <img *ngIf="currentUser.data.avatarURL" src="{{currentUser.data.avatarURL}}"
                  alt="avatar">
                <i></i>
              </span>
            </a>
            <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownProfileMenu">
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" [routerLink]="" (click)="logout()"><i class="feather ft-power"></i> Exit</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
