import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable()
export class DataService {
    constructor(public afStore: AngularFirestore) {}

    questions = [
      {
        "question": "Faked sick and went to a bar."
      },
      {
        "question": "Fallen asleep at work."
      },
      {
        "question": "Eaten my colleague’s snacks."
      },
      {
        "question": "Pretended to be productive at work."
      },
      {
        "question": "Accidentally set something on fire."
      },
      {
        "question": "Used a fake ID."
      },
      {
        "question": "Snuck into a movie."
      },
      {
        "question": "Worn pyjamas in public."
      },
      {
        "question": "Forgotten my phone in a cab."
      },
      {
        "question": "Dressed up like a chicken."
      },
      {
        "question": "Seen a ghost."
      },
      {
        "question": "Sang in the shower."
      },
      {
        "question": "Made a TikTok."
      },
      {
        "question": "Laughed about someone’s laugh."
      },
      {
        "question": "Gone bungee-jumping."
      },
      {
        "question": "Failed a diet within 24 hours."
      },
      {
        "question": "Gone hunting."
      },
      {
        "question": "Sung Karaoke."
      },
      {
        "question": "Cut my own hair and did a bad job."
      },
      {
        "question": "Made prank phone calls."
      },
      {
        "question": "Put clothes on backward and not noticed."
      },
      {
        "question": "Been sent to the principal’s office."
      },
      {
        "question": "Gotten straight A’s."
      },
      {
        "question": "Eaten snails."
      },
      {
        "question": "Eaten crocodile meat."
      },
      {
        "question": "Planted a tree."
      },
      {
        "question": "Done lip-syncing."
      },
      {
        "question": "Had a gym membership."
      },
      {
        "question": "Gotten lost in a department store."
      },
      {
        "question": "Cheated during a board game."
      },
      {
        "question": "Eaten a bug."
      },
      {
        "question": "Binge watched an entire season of a show in one day."
      },
      {
        "question": "Gotten locked out of my house."
      },
      {
        "question": "Been in a food fight."
      },
      {
        "question": "Wanted to be a movie star."
      },
      {
        "question": "Been stung by a bee."
      },
      {
        "question": "Finished a Rubik’s cube."
      },
      {
        "question": "Been in an ambulance."
      },
      {
        "question": "Punched a wall out of anger."
      },
      {
        "question": "Fallen down the stairs in front of people."
      },
      {
        "question": "Believed in monsters."
      },
      {
        "question": "Stolen a book from the library."
      },
      {
        "question": "Been Electrocuted."
      },
      {
        "question": "Wanted To Be Reborn As A Dog."
      },
      {
        "question": "Dropped Mentos Into Coke Or Pepsi."
      },
      {
        "question": "Really Liked A Song By Justin Bieber."
      },
      {
        "question": "Been Terrified Of A Theme Park Ride."
      },
      {
        "question": "Eaten A Big Mac."
      },
      {
        "question": "Worn PRADA."
      },
      {
        "question": "Tried To Count All Stars In The Sky."
      },
      {
        "question": "Snuck Out Of A Restaurant Without Paying The Bill."
      },
      {
        "question": "Gotten Trapped In An Elevator."
      },
      {
        "question": "Regifted A Gift Card."
      },
      {
        "question": "Ever Been On Stage In Front Of A Crowd."
      },
      {
        "question": "Cleared My Phone Or Computer History."
      },
      {
        "question": "Stuck Gum Under A Chair."
      },
      {
        "question": "Misspelled “Beautiful.”"
      },
      {
        "question": "Said Sorry To An Insect I Killed."
      },
      {
        "question": "Run Into A Sliding Glass Door."
      },
      {
        "question": "Camped in the middle of the woods."
      },
      {
        "question": "Felt an earthquake."
      },
      {
        "question": "Ran out of gas."
      },
      {
        "question": "Seen a lunar eclipse."
      },
      {
        "question": "Played paintball."
      },
      {
        "question": "Cried watching a Disney movie."
      },
      {
        "question": "Got COVID-19."
      },
      {
        "question": "Been in a limousine."
      },
      {
        "question": "Gone to an escape room."
      },
      {
        "question": "Fallen asleep in public transport and missed the station."
      },
      {
        "question": "Stole someone’s lunch out of the break room fridge."
      },
      {
        "question": "Worked all day in my pajamas."
      },
      {
        "question": "Tried to talk during a Zoom meeting without unmuting myself."
      },
      {
        "question": "Worked at the same company as a spouse."
      },
      {
        "question": "Worked at the same company as a parent."
      },
      {
        "question": "Had a work snack stash."
      },
      {
        "question": "Changed careers."
      },
      {
        "question": "Had a medical emergency at work."
      },
      {
        "question": "Brought my pet into the office."
      },
      {
        "question": "Broken my vow to eat healthy because there was cake or donuts in the breakroom."
      },
      {
        "question": "Snooped a coworker’s social media."
      },
      {
        "question": "Snooped a client’s social media."
      },
      {
        "question": "Got a really nice present from a client."
      },
      {
        "question": "Got a really nice present from a boss."
      },
      {
        "question": "Learned to play an instrument."
      },
      {
        "question": "Learned to speak a second language."
      },
      {
        "question": "Called in sick at work to attend an event."
      },
      {
        "question": "Gotten a speeding ticket."
      },
      {
        "question": "Been sky-diving."
      },
      {
        "question": "Played a prank on a coworker."
      },
      {
        "question": "Played a prank on my boss."
      },
      {
        "question": "Visited all 50 states."
      },
      {
        "question": "Worked at a fast food restaurant."
      },
      {
        "question": "Worked at a family business."
      },
      {
        "question": "Quit a job in a day or less."
      },
      {
        "question": "Lied in a job interview."
      },
      {
        "question": "Taken a nap during the workday while working from home."
      },
      {
        "question": "Forgotten a coworker’s name."
      },
      {
        "question": "Pretended I didn’t see an error sign on a copier because I didn’t feel like fixing it."
      },
      {
        "question": "Stayed cool as a cucumber so that customers could not tell there was an issue."
      },
      {
        "question": "Froze up during an interview"
      },
      {
        "question": "Been late to an interview."
      },
      {
        "question": "Made my coworkers watch a YouTube video."
      },
      {
        "question": "Worked a really bizarre or unusual job."
      },
      {
        "question": "Met my doppleganger."
      },
      {
        "question": "Made my own Halloween costume."
      },
      {
        "question": "Won a giant stuffed animal at a carnival or amusement park."
      },
      {
        "question": "Ridden a roller coaster 10+ times in a row."
      },
      {
        "question": "Gotten a professional psychic reading."
      },
      {
        "question": "Started a social media account for a pet."
      },
      {
        "question": "Jumped into a swimming pool with all my clothes on."
      },
      {
        "question": "Been storm-chasing."
      },
      {
        "question": "Eaten an ostrich egg."
      },
      {
        "question": "Eaten food garnished with gold flakes."
      },
      {
        "question": "Staged an elaborate photoshoot for an Instagram post."
      },
      {
        "question": "Broke out into random song in the middle of a conversation."
      },
      {
        "question": "Encountered quicksand in real life."
      },
      {
        "question": "Walked in a fashion show."
      },
      {
        "question": "Been to 3+ countries in one day."
      },
      {
        "question": "Dyed my hair a neon color."
      },
      {
        "question": "Gotten pickpocketed."
      },
      {
        "question": "Camped out to get a good place in line."
      },
      {
        "question": "Crashed a wedding."
      },
      {
        "question": "Pretended to have an accent."
      },
      {
        "question": "Mispronounced a word for years."
      },
      {
        "question": "Gotten acupuncture."
      },
      {
        "question": "Argued with a pet."
      },
      {
        "question": "Filmed a makeup tutorial."
      },
      {
        "question": "Cried when a fictional character died."
      },
      {
        "question": "Completed an entire coloring book."
      },
      {
        "question": "Sewed a button on a sweater."
      },
      {
        "question": "Solved a 1"
      },
      {
        "question": "Designed a video game."
      },
      {
        "question": "Met an internet friend in real life."
      }
    ];

    roomNames = [
      "Kael",
      "Jamier",
      "Gabe",
      "Layken",
      "Kendra",
      "Elleigh",
      "Rock",
      "Jeremih",
      "Dante",
      "Sky",
      "Akai",
      "Knowledge",
      "Moksh",
      "Jiya",
      "Gatlin",
      "Ailany",
      "Lani",
      "Aadvik",
      "Hadleigh",
      "Jowell",
      "Harlow",
      "Yaziel",
      "Raniya",
      "Miguelangel",
      "Makiyah",
      "Edric",
      "Jaymeson",
      "Gema",
      "Jenika",
      "Carlo",
      "Jaxston",
      "Tyriq",
      "Oakleigh",
      "Khalessi",
      "Avigayil",
      "Evianna",
      "Sincere",
      "Faith",
      "Truman",
      "Keelan",
      "Lilliann",
      "Gus",
      "Paizlee",
      "Marcy",
      "Dillon",
      "Heba",
      "Nyra",
      "Adrielle",
      "Abdoulaye",
      "Aaliah",
      "Sanjana",
      "Shelbie",
      "Arlen",
      "Gibson",
      "Bensen",
      "Jaedon",
      "Rebel",
      "Ross",
      "Yelitza",
      "Neriah",
      "Aycen",
      "Brookelynn",
      "Kloe",
      "Aiya",
      "Kharter",
      "Brodie",
      "Raigan",
      "Newton",
      "Teal",
      "Christon",
      "Kristian",
      "Meryl",
      "Cadence",
      "Ajani",
      "Kavi",
      "Stetson",
      "Scarlette",
      "Braedyn",
      "Andria",
      "Ziggy",
      "Yitzchak",
      "Brylon",
      "Kalleigh",
      "Josiah",
      "Bennett",
      "Gillian",
      "Lulu",
      "Affan",
      "Jill",
      "Renee",
      "Maryjane",
      "Amaryllis",
      "Rishi",
      "Jovan",
      "Anastacia",
      "Wolfgang",
      "Aviana",
      "Vladimir",
      "Nyomi",
      "Aleina",
      "Mea",
      "Marianne",
      "Addyson",
      "Nubia",
      "Zephaniah",
      "Crew",
      "Keira",
      "Jaya",
      "Ansh",
      "Kaeden",
      "Maelyn",
      "Kylan",
      "Jakoby",
      "Shayleigh",
      "Isabel",
      "Halina",
      "Laramie",
      "Alejandro",
      "Callum",
      "Jasiel",
      "Teresa",
      "Payson",
      "Yamileth",
      "Avaleigh",
      "Kadrian",
      "Alyssia",
      "Ahron",
      "Joana",
      "Nashly",
      "Ryanne",
      "Emri",
      "Adela",
      "Shrey",
      "Essence",
      "Kloee",
      "Alaysia",
      "Jahsiah",
      "Leonardo",
      "Evelina",
      "Kristine",
      "Zuriel",
      "Kendric",
      "Enzo",
      "Carsten",
      "Lauryn",
      "Emmagrace",
      "Kalani",
      "Dedrick",
      "September",
      "Carnell",
      "Tyren",
      "Jalissa",
      "Hajar",
      "Maryam",
      "Javion",
      "Orla",
      "Richelle",
      "Lilyanna",
      "Laksh",
      "Marley",
      "Ashlyn",
      "Taylon",
      "Sarai",
      "Jayonna",
      "Jodi",
      "Greysen",
      "Renae",
      "Crawford",
      "Amelia",
      "Dashawn",
      "Gentry",
      "Aloni",
      "Johndavid",
      "Gabriel",
      "Jaeda",
      "Nicolai",
      "Adelaida",
      "Sunday",
      "Emmie",
      "Marshaun",
      "Aarav",
      "Adlee",
      "Mathis",
      "Allen",
      "Sir",
      "Eren",
      "Bay",
      "Evy",
      "Lance",
      "Naiara",
      "Oona",
      "Aine",
      "Siri",
      "Karalynn",
      "Luisa",
      "Ajay",
      "Alijah",
      "Zaylin",
      "Lovely",
      "Adalee",
      "Miles",
      "Chisom",
      "Madelyne",
      "Jayren",
      "Taleen",
      "Mekhai",
      "Keyla",
      "Ishmael",
      "Oakley",
      "Azariyah",
      "Presten",
      "Sunjai",
      "Braiden",
      "Kaysen",
      "Jordy",
      "Lexani",
      "Joslyn",
      "Samari",
      "Faithlynn",
      "Lavell",
      "Dyland",
      "Jaxyn",
      "Reyli",
      "Amairani",
      "Sunnie",
      "Mackayla",
      "Shaniya",
      "Kirin",
      "Nataley",
      "Hunter",
      "Sami",
      "Jorel",
      "Paradise",
      "Albert",
      "Callee",
      "Sephora",
      "Rowdy",
      "Mariska",
      "Briley",
      "Levi",
      "Kamori",
      "Giuseppe",
      "Blaire",
      "Itzel",
      "Demetrios",
      "Bryce",
      "Hans",
      "Jaslene",
      "Mckinlee",
      "Rafaella",
      "Vedansh",
      "Justine",
      "Wade",
      "Braeden",
      "Chastity",
      "David",
      "Elisabeth",
      "Xavion",
      "Ryden",
      "Salma",
      "Taim",
      "Geovanny",
      "Bridger",
      "Phinehas",
      "Kekoa",
      "Kalen",
      "Rochelle",
      "Magdalen",
      "Kailynn",
      "Amarion",
      "Emberlynn",
      "Shannon",
      "Billy",
      "Brady",
      "Yuliana",
      "Eastyn",
      "Peighton",
      "Kenner",
      "Veda",
      "Roscoe",
      "Rylinn",
      "Ariadne",
      "Kyaire",
      "Haily",
      "London",
      "Ronnie",
      "Abimael",
      "Carlos",
      "Janely",
      "Jae",
      "Cooper",
      "Nicodemus",
      "Journee",
      "Angela",
      "Raelynn",
      "Ashten",
      "Yunus",
      "Skylynn",
      "Cing",
      "Bradley",
      "Cayleigh",
      "Glendon",
      "Adilyn",
      "Kit",
      "Sedrick",
      "Juliett",
      "Liev",
      "Jackeline",
      "Holland",
      "Hilda",
      "Wylie",
      "Aracely",
      "Kalib",
      "Dave",
      "Yeiden",
      "Lillyanne",
      "Micah",
      "Anabia",
      "Viviana",
      "Ashlee",
      "Ellieanna",
      "Vaida",
      "Terran",
      "Nahomy",
      "Boris",
      "Ilan",
      "Corbyn",
      "Marta",
      "Gregory",
      "Tali",
      "Brylynn",
      "Eryn",
      "Mayleigh",
      "Jacquelyn",
      "Allister",
      "Braydin",
      "Alta",
      "Vania",
      "Anish",
      "Nicoletta",
      "Kairo",
      "Makinlee",
      "Byron",
      "Sunny",
      "Berkley",
      "Beckham",
      "Ali",
      "Adelene",
      "Nixon",
      "Tymir",
      "Karder",
      "Analiz",
      "Milania",
      "Aspen",
      "Meika",
      "Kamrin",
      "Adhvik",
      "Kacey",
      "Tea",
      "Emory",
      "Zachery",
      "Mishika",
      "Berenice",
      "Ivan",
      "Demitri",
      "Carleigh",
      "Chrissy",
      "Katelin",
      "Carrick",
      "Cooper",
      "Kamilla",
      "Ensley",
      "Jorden",
      "Katalina",
      "Mackenzie",
      "Mirah",
      "Macoy",
      "Rhys",
      "Kohen",
      "Analee",
      "Joselin",
      "Nam",
      "Leviathan",
      "Nikoli",
      "Emy",
      "Jamyra",
      "Kylan",
      "Darin",
      "Huntley",
      "Jerick",
      "Jessy",
      "Karmen",
      "Juanita",
      "Adysen",
      "Aboubacar",
      "Zacharias",
      "Reginald",
      "Rochel",
      "Moira",
      "Gianluca",
      "Infinity",
      "Allyssa",
      "Elwood",
      "Aleksander",
      "Rocco",
      "Fabiola",
      "Chava",
      "Mehdi",
      "Natania",
      "Milly",
      "Madigan",
      "Madelyn",
      "Shiloh",
      "Keanna",
      "Saori",
      "Jaydan",
      "Atlee",
      "Lia",
      "Lydia",
      "Rishabh",
      "Deaglan",
      "Ryanna",
      "Coltin",
      "Kenzley",
      "Analise",
      "Suleiman",
      "Jazlynn",
      "Kaci",
      "Dezirae",
      "Isac",
      "Najee",
      "Walter",
      "Marion",
      "Emmylou",
      "Ameya",
      "Sahara",
      "Edie",
      "Anaisha",
      "Carmine",
      "Rommel",
      "Cove",
      "Eliah",
      "Matthew",
      "Delayna",
      "Caraline",
      "Abbigale",
      "Avah",
      "Finn",
      "Damani",
      "Wilfredo",
      "Trace",
      "Conor",
      "Atley",
      "Dalylah",
      "Nayely",
      "Elijah",
      "Jaina",
      "Prisha",
      "Yetzali",
      "Katherin",
      "Atlee",
      "Mayer",
      "Rayaan",
      "Brannon",
      "Lake",
      "Alaska",
      "Vladislav",
      "Seanna",
      "Gabby",
      "Kynadee",
      "Ian",
      "Brooks",
      "Baruch",
      "Mika",
      "Addy",
      "Smith",
      "Braden",
      "Champion",
      "Avalynn",
      "Brigitte",
      "Saoirse",
      "Dixie",
      "Journey",
      "Leilana",
      "Niamh",
      "Lupita",
      "Markus",
      "Brynleigh",
      "Jurni",
      "Jediah",
      "Reef",
      "Liesel",
      "Sofie",
      "Javonte",
      "Evalina",
      "Elija",
      "Zora",
      "Aranza",
      "Havilah",
      "Alana",
      "Payton",
      "Seraphina",
      "Hosanna",
      "Bilal",
      "Laela",
      "Kendal",
      "Sutton",
      "Lorenzo",
      "Zain",
      "Rashad",
      "Soledad",
      "Campbell",
      "Azaan",
      "Prynce",
      "Amaira",
      "Faiga",
      "Giulianna",
      "Finleigh",
      "Alfredo",
      "Dalary",
      "Johny",
      "Sam",
      "Samantha",
      "Adel",
      "Callan",
      "Vivien",
      "Payten",
      "Uzziah",
      "Aline",
      "Dominik",
      "Auria",
      "Aveah",
      "Zamya",
      "Tytus",
      "Lianna",
      "Shawn",
      "Javien",
      "Kacy",
      "Adolfo",
      "Mariella",
      "Montez",
      "Larkin",
      "Kylie",
      "Aeva",
      "Bradly",
      "Rhiley",
      "Raul",
      "Freedom",
      "Aram",
      "Quest",
      "Rudy",
      "Syed",
      "Daymian",
      "Kamron",
      "Jaylin",
      "Achilles",
      "Sanaa",
      "Karime",
      "Azul",
      "Torrey",
      "Valkyrie",
      "Briasia",
      "Yumi",
      "Madison",
      "Abubakar",
      "Rayyan",
      "Elexis",
      "Arrianna",
      "Maliyah",
      "Faris",
      "Jhett",
      "Ansley",
      "Edrick",
      "Mason",
      "Kate",
      "Geoffrey",
      "Cassia",
      "Harlow",
      "Sari",
      "Jazaria",
      "Zaiya",
      "Carmella",
      "Tyus",
      "Russell",
      "Remy",
      "Ayesha",
      "Amir",
      "Kyleah",
      "Kush",
      "Cedric",
      "Maci",
      "Avionna",
      "Lyndon",
      "West",
      "Aryiah",
      "Katniss",
      "Anthony",
      "Nikita",
      "Anyiah",
      "Meredith",
      "Vail",
      "Hadley",
      "Gloriana",
      "Vance",
      "Ciara",
      "Akasha",
      "Adalene",
      "Reymundo",
      "Finnley",
      "Jerimiah",
      "Jizelle",
      "Tabitha",
      "Katarina",
      "Ridley",
      "Fiorella",
      "Naia",
      "Camden",
      "Tanvi",
      "Jayvon",
      "Dane",
      "Tru",
      "Blanca",
      "Watson",
      "Samara",
      "Tyrese",
      "Imanol",
      "Frankie",
      "Mitzi",
      "Jolina",
      "Dakotah",
      "Teagan",
      "Yaretzy",
      "Clayton",
      "Javon",
      "Chole",
      "Levi",
      "Sylvan",
      "Elvin",
      "Ahsan",
      "Clark",
      "Vivianne",
      "Sheyla",
      "Whit",
      "Jacari",
      "Tabatha",
      "Rileigh",
      "Kyrin",
      "Carlton",
      "Miyana",
      "Sydnie",
      "Breeze",
      "Ameria",
      "Shifra",
      "Cyrus",
      "Alonna",
      "Cambria",
      "Israel",
      "Camilla",
      "Rayce",
      "Getsemani",
      "Yamil",
      "Adil",
      "Saron",
      "Eriana",
      "Barbara",
      "Sedona",
      "Eris",
      "Demarcus",
      "Ainhoa",
      "Maelani",
      "Finn",
      "Abel",
      "Campbell",
      "Kolson",
      "Daylin",
      "Jameel",
      "Rynn",
      "Jarvis",
      "Randall",
      "Margarita",
      "Kaelani",
      "Sabastian",
      "Emmy",
      "Exavier",
      "Maris",
      "Aylen",
      "Bennie",
      "Sigrid",
      "Quinlan",
      "Rilee",
      "Emina",
      "Freeman",
      "Amaia",
      "Dara",
      "Emiko",
      "Mackenzy",
      "Devora",
      "Ziyad",
      "Jerrod",
      "Blaze",
      "Karlos",
      "Ayonna",
      "Salem",
      "Ariel",
      "Haya",
      "Addalyn",
      "Lila",
      "Kaliah",
      "Svea",
      "Lina",
      "Simone",
      "Dua",
      "Melvin",
      "Henna",
      "Kevyn",
      "Nisa",
      "Talia",
      "Roxana",
      "Mackenna",
      "Amerie",
      "Analyn",
      "Dean",
      "Alise",
      "Jireh",
      "Adaya",
      "Zavier",
      "Jayda",
      "Elani",
      "Carissa",
      "Seth",
      "Ciaran",
      "Iyanna",
      "Damien",
      "Teegan",
      "Naira",
      "Makari",
      "Roque",
      "Melany",
      "Emely",
      "Jadon",
      "Aadya",
      "Bray",
      "Younis",
      "Divine",
      "Finnian",
      "Atreyu",
      "Jace",
      "Aviya",
      "Ziona",
      "Cloey",
      "Livie",
      "Amorette",
      "Skylah",
      "Naisha",
      "Syler",
      "Mirella",
      "Kavion",
      "Jalisa",
      "Skylyn",
      "Amerika",
      "Croix",
      "Sebastien",
      "Johannes",
      "Tamia",
      "Makaio",
      "Delina",
      "Layne",
      "Kenlie",
      "Anagha",
      "Findley",
      "Sandy",
      "Maycee",
      "Legend",
      "Elly",
      "Rider",
      "Jonny",
      "Ivy",
      "Kollins",
      "Yannick",
      "Austynn",
      "Ammar",
      "Myriam",
      "Khylen",
      "Joanie",
      "Kensleigh",
      "Imaan",
      "Ryken",
      "Karsyn",
      "Cristo",
      "Kade",
      "Kyler",
      "Storm",
      "Briella",
      "Kaiya",
      "Miley",
      "Paden",
      "Kaden",
      "Esai",
      "Eshaan",
      "Tinley",
      "Elie",
      "Rosalia",
      "Lourdes",
      "Ahlam",
      "Ianna",
      "Emelina",
      "Case",
      "Cj",
      "Alasdair",
      "Aylee",
      "Breasia",
      "Jarett",
      "Shahd",
      "Joan",
      "Benzion",
      "Gustavo",
      "Zyon",
      "Keiry",
      "Mylah",
      "Jaice",
      "Kiyan",
      "Amar",
      "Aneesh",
      "Zakariah",
      "Niam",
      "Arlene",
      "Cherokee",
      "Laney",
      "Koen",
      "Alexia",
      "Vito",
      "Brenna",
      "Mischa",
      "Coraline",
      "Cate",
      "Riona",
      "Kristi",
      "Marty",
      "Abriella",
      "Adhya",
      "Maclin",
      "Dierks",
      "Malachi",
      "Cord",
      "Erick",
      "Railyn",
      "Pierson",
      "Maverik",
      "Filip",
      "Chance",
      "Dinah",
      "Aerial",
      "Dana",
      "Quincy",
      "Dayla",
      "Jaidah",
      "Laquan",
      "Ximena",
      "Zury",
      "Alysia",
      "Van",
      "Anaya",
      "Daleyza",
      "Beatriz",
      "Sire",
      "Leyla",
      "Ryann",
      "Micaiah",
      "Japheth",
      "Varnika",
      "Donna",
      "Aarya",
      "Ayub",
      "Elon",
      "Anvika",
      "Dream",
      "Anila",
      "Faisal",
      "August",
      "Cherry",
      "Mailyn",
      "Keziah",
      "Manuela",
      "Addelynn",
      "Mallie",
      "Jaylon",
      "Emmett",
      "Jamieson",
      "Jeanne",
      "Harley",
      "Alexi",
      "Rylie",
      "Jesiah",
      "Grettel",
      "Cheryl",
      "Vihan",
      "Krystal",
      "Emilie",
      "Katharina",
      "Dynasty",
      "Harry",
      "Hope",
      "Huxley",
      "Princess",
      "Uma",
      "Arley",
      "Serah",
      "Keller",
      "Wynn",
      "Summit",
      "Eowyn",
      "Rosalind",
      "Preslie",
      "Liberty",
      "Jaylen",
      "Joanne",
      "Maribelle",
      "Jairus",
      "Josalynn",
      "Raelynne",
      "Star",
      "Elli",
      "Adaleigh",
      "Leviticus",
      "Macey",
      "Evelyn",
      "Julian",
      "Ami",
      "Silvestre",
      "Xoe",
      "Kaleigh",
      "Rosalyn",
      "Romi",
      "Halo",
      "Maylene",
      "Amin",
      "Rooney",
      "Ruhi",
      "Madelynn",
      "Dino",
      "Leopoldo",
      "Ty",
      "Irelynn",
      "Hali",
      "Jamia",
      "Niles",
      "Aizah",
      "Aarushi",
      "Zoee",
      "Armon",
      "Lion",
      "Victoria",
      "Beatrice",
      "Kaito",
      "Lebron",
      "Yulianna",
      "Sima",
      "Charley",
      "Connor",
      "Jeannette",
      "Avianna",
      "Mckenzee",
      "Irina",
      "Jozie",
      "Adryan",
      "Favour",
      "Husna",
      "Annabel",
      "Shanvi",
      "Aryana",
      "Bailee",
      "Winter",
      "Clio",
      "Terry",
      "Jamie",
      "Nirvi",
      "Ravenna",
      "Kelli",
      "Analiah",
      "Macklin",
      "Celine",
      "Lacee",
      "Ayelet",
      "Navaeh",
      "Noor",
      "Grahm",
      "Khaliya",
      "Aaditya",
      "Eliud",
      "Westyn",
      "Venice",
      "Abagail",
      "Khayden",
      "Lilyanne",
      "Giovani",
      "Treyson",
      "Yousuf",
      "Saylor",
      "Jason",
      "Mykah",
      "Evander",
      "Aalayah",
      "Makenzie",
      "Raquel",
      "Novaleigh",
      "Taleah",
      "Pilar",
      "Roxas",
      "Chasity",
      "Yessica",
      "Rainer",
      "Jax",
      "Kenadee",
      "Armani",
      "Shmuel",
      "Aislyn",
      "Om",
      "Ares",
      "Zyonna",
      "Zemirah",
      "Naveen",
      "Tziporah",
      "Riggs",
      "Wilson",
      "Damion",
      "Masen",
      "Lillianne",
      "Amelie",
      "Catalia",
      "Jessi",
      "Zaylie",
      "Luca",
      "Morghan",
      "Jameis",
      "Avie",
      "Jeziah",
      "Maximiliano",
      "Agastya",
      "Hassan",
      "Kroy",
      "Maricela",
      "Jaiya",
      "Caitlyn",
      "Isadora",
      "Temperance",
      "Alisson",
      "Dilynn",
      "Zev",
      "Louella",
      "Tucker",
      "Brayson",
      "Alyric",
      "Loren",
      "Mavrik",
      "Abbie",
      "Heather",
      "Galileah",
      "Elyse",
      "Slade",
      "Nosson",
      "Shirley",
      "Gweneth",
      "Trent",
      "Maria",
      "Evalee",
      "Jesse",
      "Korbin",
      "Keyra",
      "Sierra",
      "Khaleel",
      "Mohamed",
      "Clarabelle",
      "Lian",
      "Saxton",
      "Hadlie",
      "Stefan",
      "Ivaan",
      "Shawna",
      "Saw",
      "Steele",
      "Jerome",
      "Sol",
      "Bellah",
      "Lesley",
      "Jacen",
      "Jude",
      "Noa",
      "Alida",
      "Talitha",
      "Ciana",
      "Dayron",
      "Frankie",
      "Laynee",
      "Giovanni",
      "Skylar",
      "Samanta",
      "Odin",
      "Amoni",
      "Pharaoh",
      "Sidney",
      "Analiyah",
      "Kemora",
      "Kaloni",
      "Jordyn",
      "Kynzlee",
      "Jayleen",
      "Lindsay",
      "Moxie",
      "Tyra",
      "Karim",
      "Bruce",
      "Ainslee",
      "Jayna",
      "Bowen",
      "Rosella",
      "Jalani",
      "Demian",
      "Kenyon",
      "Kristiana",
      "Avin",
      "Davi",
      "Dayne",
      "Nihira",
      "Annalynn",
      "Stephania",
      "Axton",
      "Ender",
      "Isabella",
      "Mirielle",
      "Jaedyn",
      "Alba",
      "Natali",
      "Sury",
      "Gauge",
      "Wilma",
      "Arham",
      "Kriti",
      "Zaydee",
      "Arabella",
      "Branson",
      "Casper",
      "Anay",
      "Emmaleigh",
      "Starr",
      "Ayomide",
      "Kaine",
      "Pearce",
      "Nevin",
      "Blakelynn",
      "Inayah",
      "Viaan",
      "Abrish",
      "Bianka",
      "Cordelia",
      "Cheston",
      "Breonna",
      "Anaira",
      "Saray",
      "Makiya",
      "Alizah",
      "Naylah",
      "Jeison",
      "Elton",
      "Aaradhya",
      "Jareth",
      "Marvin",
      "Katiana",
      "Shaylin",
      "Rafaela",
      "Alliyah",
      "Caidence",
      "Xiomara",
      "Raeghan",
      "Marely",
      "Xavier",
      "Keondre",
      "Lael",
      "Quinten",
      "Bailey",
      "Katia",
      "Betsaida",
      "Armando",
      "Terrell",
      "Emerie",
      "Aniela",
      "Naftali",
      "Alahni",
      "Jerzee",
      "Layah",
      "Kaidon",
      "Shlok",
      "Cathryn",
      "Hudsen",
      "Breccan",
      "Dejah",
      "Theseus",
      "Jessica",
      "Keeton",
      "Aziah",
      "Mackenzi",
      "Fionn",
      "Lenore",
      "Kobie",
      "Penelope",
      "Kynley",
      "Mikail",
      "Soham",
      "Ananias",
      "Boston",
      "Bryton",
      "Zebadiah",
      "Siobhan",
      "Edan",
      "Binyamin",
      "Eidan",
      "Morris",
      "Sean",
      "Unknown",
      "Symphony",
      "Kirill",
      "Kyler",
      "Finley",
      "Alistair",
      "Gregorio",
      "Saleen",
      "Errol",
      "Janet",
      "Marianna",
      "Kimora",
      "Nadiya",
      "Demi",
      "Avion",
      "Shaindy",
      "Topanga",
      "Benuel",
      "Lorelei",
      "Heidi",
      "Pressley",
      "Judah",
      "Ryland",
      "Wayde",
      "Shauna",
      "Tadeo",
      "Ted",
      "Kyree",
      "Ishaan",
      "Oak",
      "Denim",
      "Rye",
      "Elisabet",
      "Debora",
      "Lilliana",
      "Eesa",
      "Jorge",
      "Kadynce",
      "Emmarie",
      "Jaselle",
      "Irie",
      "Amaru",
      "Tristin",
      "Raine",
      "Charbel",
      "Avyaan",
      "Dolly",
      "Crystal",
      "Dereck",
      "Izabelle",
      "Camellia",
      "Jabria",
      "Madden",
      "Beaux",
      "Jazelle",
      "Keagen",
      "Galileo",
      "Myer",
      "Rachael",
      "Yael",
      "Bradleigh",
      "Abella",
      "Julie",
      "Tahiry",
      "Navya",
      "Tidus",
      "Emree",
      "Jancarlos",
      "Skyler",
      "Parisa",
      "Agatha",
      "Kaylana",
      "Niklas",
      "Queen",
      "Selah",
      "Adamari",
      "Marylou",
      "Ronnie",
      "Bayley",
      "Addisyn",
      "Maizey",
      "Ariyanna",
      "Muhammad",
      "Noemi",
      "Sameera",
      "Davian",
      "Jeancarlos",
      "Legaci",
      "Jarrod",
      "Ronald",
      "Ward",
      "Brendan",
      "Zayla",
      "Fletcher",
      "Jhase",
      "Eliyah",
      "Meara",
      "Tesla",
      "Azari",
      "Soha",
      "Santos",
      "Emaleigh",
      "Italy",
      "Jena",
      "Zina",
      "Irene",
      "Elisheva",
      "Oaklie",
      "Gracelyn",
      "Collette",
      "Benton",
      "Stephon",
      "Yovani",
      "Aubrie",
      "Calise",
      "Kaylene",
      "Aliyanna",
      "Delaney",
      "Bethel",
      "Jahzara",
      "Cassidee",
      "Sumayyah",
      "Haven",
      "Sahil",
      "Ayden",
      "Jaeden",
      "Chanelle",
      "Talyn",
      "Gianni",
      "Taytum",
      "Adrienne",
      "Georgie",
      "Aadhav",
      "Javen",
      "Ainslie",
      "Rishik",
      "Franchesca",
      "Patricia",
      "Kole",
      "Zeth",
      "Muhammed",
      "Kian",
      "Jaythan",
      "Copeland",
      "Mikaylah",
      "Lucile",
      "Kayli",
      "November",
      "Loreal",
      "Gia",
      "Marcell",
      "Mckenzie",
      "Kaedyn",
      "Kawika",
      "Darey",
      "Lorelai",
      "Haiden",
      "Shayaan",
      "Sailor",
      "Eric",
      "Antione",
      "Jhonny",
      "Kirby",
      "Marlee",
      "Yaqub",
      "Micayla",
      "Ramone",
      "Kurtis",
      "Ira",
      "Jenae",
      "Precious",
      "Bentleigh",
      "Ryker",
      "Zyanna",
      "Wren",
      "Roman",
      "Raylie",
      "Keaton",
      "Acelyn",
      "Maher",
      "Breah",
      "Sammy",
      "Corban",
      "Aubrey",
      "Mazzy",
      "Aayden",
      "Aaryan",
      "Lucinda",
      "Dewayne",
      "Maritza",
      "Hanson",
      "Aylani",
      "Anirudh",
      "Fay",
      "Trae",
      "Kennadi",
      "Saige",
      "Kayloni",
      "Aubreyanna",
      "Nicolette",
      "Raizel",
      "Ayra",
      "Bibiana",
      "Neela",
      "Edwardo",
      "Radley",
      "Brooklyn",
      "Prestyn",
      "Willy",
      "Belle",
      "Uriel",
      "Ihsan",
      "Abrianna",
      "Faizan",
      "Everardo",
      "Aydan",
      "Myka",
      "Akemi",
      "Yousef",
      "Tytan",
      "Amy",
      "Benyamin",
      "Rivers",
      "Zyra",
      "Evee",
      "Layla",
      "Yehuda",
      "Gianfranco",
      "Skylan",
      "Mouhamed",
      "Cassidy",
      "Miral",
      "Juan",
      "Muna",
      "Crimson",
      "Marlon",
      "Jaeger",
      "Sanad",
      "Avalyn",
      "Alyvia",
      "Evey",
      "Maddilyn",
      "Goldy",
      "Ariyah",
      "Kenadie",
      "Emilio",
      "Pete",
      "Lynnlee",
      "Nassir",
      "Adalena",
      "Johnathan",
      "Yisrael",
      "Kevon",
      "Diamond",
      "Zechariah",
      "Alara",
      "Aisling",
      "Ellanora",
      "Jahdiel",
      "Ben",
      "Deysi",
      "Karl",
      "Braylin",
      "Tate",
      "Jarod",
      "Kodie",
      "Camri",
      "Naksh",
      "Mckinley",
      "Rian",
      "Canyon",
      "Saanvi",
      "Kennadie",
      "Zanaya",
      "Maxime",
      "Corwin",
      "Yael",
      "Morgan",
      "Elayna",
      "Carina",
      "Azarias",
      "Carmelo",
      "Silvia",
      "Rylan",
      "Andie",
      "Christal",
      "Yvonne",
      "Jaden",
      "Bode",
      "Jostin",
      "Jabril",
      "Mattea",
      "Clive",
      "Roland",
      "Calianna",
      "Emmalynne",
      "Shea",
      "Daven",
      "Jeylin",
      "Andersen",
      "Eliya",
      "Aslan",
      "Teigan",
      "Sebastian",
      "Clarke",
      "Mirabella",
      "Nayan",
      "Gil",
      "Cameryn",
      "Antonia",
      "Ashleigh",
      "Romy",
      "Ayat",
      "Emoree",
      "Cristina",
      "Unity",
      "Alayna",
      "Parker",
      "Octavia",
      "Kathryn",
      "Maddyn",
      "Veronika",
      "Seren",
      "Don",
      "Jayne",
      "Dyani",
      "Darby",
      "Eduardo",
      "Shai",
      "Angelique",
      "Bryston",
      "Abdelrahman",
      "Maha",
      "Jalynn",
      "Annalea",
      "Zissy",
      "Brodi",
      "Keagan",
      "Janell",
      "Chyanne",
      "Carla",
      "Janylah",
      "Shalom",
      "Brea",
      "Blossom",
      "Kayson",
      "Yatzil",
      "Hailey",
      "Joni",
      "Severin",
      "Jaxton",
      "Lukah",
      "Etta",
      "Annaleah",
      "Jayton",
      "Sylas",
      "Ariyan",
      "Xavian",
      "Demiyah",
      "Vida",
      "Seven",
      "Luqman",
      "Kambrie",
      "Patton",
      "Santhiago",
      "Fatoumata",
      "Hoyt",
      "Jackson",
      "Jacolby",
      "Braylyn",
      "Pavel",
      "Tatianna",
      "Sara",
      "Charleston",
      "Jamila",
      "Prince",
      "Zion",
      "Denver",
      "Landree",
      "Pamela",
      "Regan",
      "Dora",
      "Jaycee",
      "Dhyan",
      "Agnes",
      "Gloria",
      "Rania",
      "Richard",
      "Robel",
      "Kaylan",
      "Lucian",
      "Evyn",
      "Ilona",
      "Karlie",
      "Lionel",
      "Kingstyn",
      "Rocket",
      "Lyrik",
      "Doreen",
      "Justice",
      "Tory",
      "Aliannah",
      "Havyn",
      "Mariam",
      "Kashius",
      "Adrian",
      "Eliazar",
      "Ailyn",
      "Saketh",
      "Harvey",
      "Daniella",
      "Kenslie",
      "Obadiah",
      "Latrell",
      "Keion",
      "Tarek",
      "Kaisen",
      "Winnie",
      "Derrick",
      "Terron",
      "Gabino",
      "Kamryn",
      "Raylene",
      "Zari",
      "Kree",
      "Brienne",
      "Zeina",
      "Viggo",
      "Deangelo",
      "Thea",
      "Leeah",
      "Analia",
      "Allan",
      "Rocky",
      "Yasiel",
      "Breana",
      "Callan",
      "Keyon",
      "Mariano",
      "Onna",
      "Asiel",
      "Adamaris",
      "Marlie",
      "Miamor",
      "Yitzchok",
      "Conlan",
      "Arlo",
      "Remmi",
      "Atalia",
      "Rueben",
      "Castiel",
      "Emir",
      "Kierra",
      "Kamari",
      "Winslow",
      "Bryson",
      "Josyah",
      "Elnathan",
      "Camryn",
      "Nahum",
      "Karizma",
      "Arsen",
      "Kysen",
      "Ezequiel",
      "Amyla",
      "Liv",
      "Hazelle",
      "Sohan",
      "Blakley",
      "Camdyn",
      "Kirby",
      "Ingrid",
      "Elma",
      "Kristoff",
      "Lizzy",
      "Exodus",
      "Stevan",
      "Braydyn",
      "Zyair",
      "Fernando",
      "Harmonii",
      "Izabela",
      "Haris",
      "Manha",
      "Savanna",
      "Izel",
      "Juna",
      "Khyrie",
      "Aalyah",
      "Mattias",
      "Kay",
      "Rhett",
      "Hana",
      "Dina",
      "Adyn",
      "Lelia",
      "Marin",
      "Kage",
      "Belinda",
      "Emerson",
      "Forever",
      "Kyana",
      "Ramsey",
      "Yohanna",
      "Tirzah",
      "Lela",
      "Brent",
      "Leland",
      "Mattix",
      "Lynnix",
      "Ameenah",
      "Zian",
      "Elisia",
      "Angel",
      "Avree",
      "Jayce",
      "Anisha",
      "Christelle",
      "Mikenna",
      "Santana",
      "Lainie",
      "Kensley",
      "Bastian",
      "Nava",
      "Mabry",
      "Keniel",
      "Daniela",
      "Taj",
      "Yazmine",
      "Anaiah",
      "Ascher",
      "Salwa",
      "Ignatius",
      "Ayman",
      "Johannah",
      "Amylia",
      "Jaysen",
      "Katlyn",
      "Amiliana",
      "Sergio",
      "Alia",
      "Adna",
      "Letty",
      "Addilyn",
      "Beau",
      "Annette",
      "Jaydee",
      "Kenlee",
      "Jewels",
      "Deema",
      "Ishika",
      "Jacie",
      "Lilah",
      "Jc",
      "Ivanka",
      "Breck",
      "Tayvion",
      "Malique",
      "Yasmine",
      "Kelsy",
      "Jasmyne",
      "Kainan",
      "Chrislynn",
      "Nathalie",
      "Jailyn",
      "Randal",
      "Robby",
      "Blakely",
      "Caesar",
      "Anylah",
      "Niall",
      "Kolsen",
      "Octavian",
      "Arden",
      "Sudais",
      "Phoenix",
      "Aarna",
      "Kacen",
      "Huda",
      "Chanell",
      "Mohamad",
      "Amna",
      "Jaia",
      "Leopold",
      "Rowyn",
      "Charli",
      "Avan",
      "Anaiyah",
      "Devin",
      "Iman",
      "Mehmet",
      "Akhil",
      "Demar",
      "Avi",
      "Viola",
      "Matthias",
      "Jaydon",
      "Arielle",
      "Saina",
      "Nalia",
      "Greer",
      "Kailah",
      "Dajuan",
      "Ariyanah",
      "Mariangel",
      "Kyng",
      "Linnea",
      "Minna",
      "Mahalia",
      "Coleman",
      "Addysen",
      "Draco",
      "Madelynne",
      "Ander",
      "Myanna",
      "Suhaylah",
      "Lillyana",
      "Charlee",
      "Vanessa",
      "Bobbi",
      "Karon",
      "Amyah",
      "Sonny",
      "Aivah",
      "Kaylon",
      "Waverly",
      "Elynn",
      "Kal",
      "Kody",
      "Blayne",
      "Avram",
      "Jacquelynn",
      "Gunnar",
      "Lennon",
      "Grey",
      "Tylen",
      "Ysabel",
      "Fianna",
      "Archie",
      "Adria",
      "Keila",
      "Adelle",
      "Mostafa",
      "Adelie",
      "Marcella",
      "Manasvi",
      "Misa",
      "Honey",
      "Ron",
      "Bellarose",
      "Arsema",
      "Nikole",
      "Annabelle",
      "Mario",
      "Kayleen",
      "Trapper",
      "Margeaux",
      "Samar",
      "Ashtin",
      "Zacchaeus",
      "Kamil",
      "Scarlett",
      "Solara",
      "Fannie",
      "Renesmee",
      "Yaakov",
      "Erion",
      "Kruz",
      "Issa",
      "Cecilia",
      "Dakota",
      "Zayne",
      "Eldana",
      "Evany",
      "Gwenyth",
      "Melodi",
      "Priscila",
      "Adalie",
      "Imani",
      "Paityn",
      "Lily",
      "Selim",
      "Jayci",
      "Ellis",
      "Camora",
      "Liliana",
      "Trinity",
      "Estefany",
      "Ashur",
      "Lysander",
      "Hesston",
      "Nyasia",
      "Majd",
      "Aleeza",
      "Shelby",
      "Pryce",
      "Graeson",
      "Yulian",
      "Basil",
      "Abhay",
      "Rowan",
      "Aviah",
      "Divinity",
      "Carsyn",
      "Elyjah",
      "Theo",
      "Shlome",
      "Hisham",
      "Kynleigh",
      "Kiaan",
      "Quorra",
      "Weston",
      "Riley",
      "Marwan",
      "Jovany",
      "Darron",
      "Logann",
      "Madaline",
      "Shia",
      "Kenneth",
      "Abbigail",
      "Aysha",
      "Izabell",
      "Liviana",
      "Zoriah",
      "Sione",
      "Salena",
      "Vita",
      "Kendell",
      "Bryn",
      "Blen",
      "Jasiah",
      "Raymundo",
      "Teaghan",
      "Lamar",
      "Azriel",
      "Sophie",
      "Hartlee",
      "Apryl",
      "Charlie",
      "Delani",
      "Malea",
      "Bricen",
      "Coulson",
      "Lou",
      "Samia",
      "Avarie",
      "Kylo",
      "Sia",
      "Anayeli",
      "Reece",
      "Leighann",
      "Shalom",
      "Gracen",
      "Shaylynn",
      "Clara",
      "Gretchen",
      "Laykin",
      "Jaxsen",
      "Charlotte",
      "Kaleo",
      "Ana",
      "Unique",
      "Jalen",
      "Ema",
      "Sabine",
      "Xzavier",
      "Zakary",
      "Brogan",
      "Jentry",
      "Lavina",
      "Lex",
      "Jakyra",
      "Bogdan",
      "Parks",
      "Tremaine",
      "Roni",
      "Stacy",
      "Iver",
      "Leeroy",
      "Lylah",
      "Xane",
      "Delyla"
    ];

    setupData(){

        this.afStore.collection('questions').get().subscribe(qs=>{
            if (qs.size == 0) {
                this.questions.map(question=>{
                    this.afStore.collection('questions').add({
                        question: question.question
                    })
                })        
            }
        })

        this.afStore.collection('roomsRef').get().subscribe(rs=>{
          if (rs.size == 0) {
            this.roomNames.map(room=>{
              this.afStore.collection('roomsRef').add({
                roomName: room
              })
            })
          }
        })
    }

    getQuestions() {
      return this.afStore.collection('questions').get();
    }

    getRoomsRef() {
      return this.afStore.collection('roomsRef', ref=>ref.limitToLast(10).orderBy('roomName')).get();
    }

    getNextQuestion(roomName) {
      return this.afStore.collection('activeRooms').doc(roomName).snapshotChanges();
    }

    setNextQuestionForEveryone(roomName, question, counter) {
      return this.afStore.collection('activeRooms').doc(roomName).set({
        question: question,
        gameStarted: true,
        counter: counter
      }).then(()=>{
        this.afStore.collection('users').get().subscribe(users=>{
          users.docs.map(user=>{
            this.afStore.collection('users').doc(user.id).update({
              currentResponse: ""
            })
          })
        })
      })
    }

    getQuizMaster(roomName) {
      return this.afStore.collection('users', ref=>ref.where("quizMaster", "==", true).where('roomName','==', roomName)).snapshotChanges();
    }

    getActiveRooms() {
      return this.afStore.collection('users', ref=>ref.orderBy('score')).snapshotChanges();
    }

    setQuizMaster(uid, timer) {
      return this.afStore.collection('users').doc(uid).update({
        quizMaster: true
      }).then(()=>{
      })
    }

    setResponse(user, val){
      let newScore = user.data.score + (val ? 0:1)
      console.log(newScore, user);
      return this.afStore.collection('users').doc(user.uid).update({
        currentResponse: (val ? 'I HAVE': 'I HAVE NOT'),
        score: newScore
      })
    }

    joinRoom(roomName, uid) {
      return this.afStore.collection('users').doc(uid).update({
        roomName: roomName
      })
    }

    resetGame(roomName) {
      this.afStore.collection('users', ref=>ref.where('roomName', '==', roomName)).get().subscribe(users=>{
        users.docs.map(user=>{
          this.afStore.collection('users').doc(user.id).update({
            score: 0
          })
        })
      })
    }
}
