<div class="app-content content">
    <div class="content-wrapper">
      <div class="content-header row mb-1">
      </div>
      <div class="content-body">
        <section id="drag-area">
          <div class="row">
            <div class="col-md-12" *blockUI="'changelog'; message: 'Loading'">
              <m-card [options]="options" (reloadFunction)="reloadChangelog($event)">
                <ng-container mCardHeaderTitle>
                </ng-container>
                <ng-container mCardBody>
                    <div class="card-text" >
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                  <div class="spinner-border" role="status" *ngIf="!roomsRef || !currentUser.data">
                                    <span class="sr-only">Loading...</span>
                                  </div>                                  
                                    <div class="col-md-12 text-center" *ngIf="roomsRef && currentUser.data">
                                        <button (click)="startNewRoom()" class="btn btn-outline-success timer" >Start New Room</button>
                                        <button (click)="joinRoom()" class="btn btn-outline-success timer ml-2" >Join Room</button>
                                    </div>                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
              </m-card>
            </div>
          </div>
        </section>
      </div>
    </div>
</div>
