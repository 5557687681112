import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';


@Injectable()
export class AuthService {
  constructor(public afAuth: AngularFireAuth, public afStore: AngularFirestore) {}

  // Facebook login
  doFacebookLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.FacebookAuthProvider();
      this.afAuth.signInWithPopup(provider).then(
        res => {
          resolve(res);
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  // Github login
  doGitHubLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.GithubAuthProvider();
      this.afAuth.signInWithPopup(provider).then(
        res => {
          resolve(res);
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  // Twitter login
  doTwitterLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.TwitterAuthProvider();
      this.afAuth.signInWithPopup(provider).then(
        res => {
          resolve(res);
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  // Google login
  doGoogleLogin() {
    return new Promise<any>((resolve, reject) => {
      const provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      this.afAuth.signInWithPopup(provider).then(
        res => {
          resolve(res);
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  // Register
  doRegister(value) {
    return new Promise<any>((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(value.email, value.password)
        .then(
          res => {
            resolve(res);
          },
          err => reject(err)
        );
    });
  }

  // Login
  doLogin(value) {
    return new Promise<any>((resolve, reject) => {
      firebase
        .auth()
        .signInAnonymously()
        .then(
          res => {
            this.createUser(res, value);
            resolve(res);
          },
          err => reject(err)
        );
    });
  }

  // Logout
  doLogout() {
    return new Promise<void>((resolve, reject) => {
      if (firebase.auth().currentUser) {
        localStorage.removeItem('currentUser');
        localStorage.removeItem('remember');
        this.afStore.collection('users').doc(firebase.auth().currentUser.uid).delete().then(()=>{
          this.afAuth.signOut();
          resolve();
        });
      } else {
        localStorage.removeItem('currentUser');
        resolve();
      }
    });
  }

  createUser(user, value) {
    this.afStore.collection('users').doc(user.user.uid).set({
      nickName: value.nickName,
      createdDateTime: new Date(),
      avatarURL: "https://avatars.dicebear.com/api/avataaars/" + user.user.uid + ".svg",
      score: 0
    })
  }

  getActiveUsersInRoom(roomName) {
    return this.afStore.collection('users', 
      ref=>ref.where('roomName','==', roomName).orderBy('score', 'desc')).snapshotChanges();
  }

  public getCurrentUser(uid){
      return this.afStore.collection('users').doc(uid).snapshotChanges();
  }
}


