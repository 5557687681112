import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import { DataService } from '../_services/data.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {

  constructor(public auth: AuthService, public dataService: DataService, private router: Router) { }
  currentUser;

  roomsRef;
  roomsRefListener;

  ngOnInit(): void {
    //once off setup
    this.dataService.setupData();

    if (localStorage.getItem('currentUser')) {
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.auth.getCurrentUser(this.currentUser.uid).subscribe(user=>{
        this.currentUser.data = user.payload.data();
      })
    }

    this.roomsRefListener = this.dataService.getRoomsRef().subscribe(rr=>{
      this.roomsRef = rr.docs.map(a => {
        const data = a.data();
        const id = a.id;
        return { id, ...(data as Record<string, unknown>) };
      });
    })
  }

  async joinRoom() {
    const { value: roomName } = await Swal.fire({
      title: 'Room To Join',
      input: 'text',
      inputLabel: 'Room Name',
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return 'You need a room to join! or Do you want to start a new room?'
        }
      }
    })
    
    if (roomName) {
      let roomN : string = roomName;
      roomN = roomN.toLowerCase();
      this.router.navigate(['/room'],  { queryParams: { roomName: roomN } })
    }
  }
  
  async startNewRoom() {
    Swal.fire({
      title:'Creating New Room...',
      showConfirmButton : false,
      allowOutsideClick: false
     });
    Swal.showLoading();
    let newRoom :string = this.roomsRef.sort(() => Math.random() - 0.5).slice(0, 1)[0].roomName;
    newRoom = newRoom.toLowerCase();
    Swal.hideLoading();
    Swal.close();
     this.router.navigate(['/room'],  { queryParams: { roomName: newRoom } })
  }

  @HostListener('window:beforeunload')
  async ngOnDestroy(){
    if (this.roomsRefListener)
      this.roomsRefListener.unsubscribe();
  }
}
