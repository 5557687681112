<div class="app-content content pull-left equal">
    <div class="content-wrapper">
      <div class="content-header row mb-1">
      </div>
      <div class="content-body">
        <section id="drag-area">
          <div class="row">
            <div class="col-md-12" *blockUI="'changelog'; message: 'Loading'">
              <m-card [options]="options" (reloadFunction)="reloadChangelog($event)">
                <ng-container mCardHeaderTitle>
                </ng-container>
                <ng-container mCardBody>
                    <div class="card-text" >
                        <div class="card" *ngIf="qMasterSet">
                            <div class="card-content">
                              <div class="card-body" *ngIf="nextQuestion">
                                <h4 class="card-title text-center">Never Have I Ever</h4>
                                <div class="row" *ngIf="nextQuestion">
                                    <div class="col-md-12 text-center">
                                        <span class="question" >{{nextQuestion.question.question}}</span>
                                    </div>
                                </div>
                                <div class="row mt-2" *ngIf="nextQuestion">
                                    <div class="col-md-12 text-center">
                                        <span class="btn btn-outline-amber timer "><countdown #cd [config]="config" (event)="handleEvent($event)"></countdown></span>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-6 text-center" *ngIf="currentUser && currentUser.data && currentUser.data.quizMaster">
                                        <button (click)="showNextQuestion()" class="btn btn-outline-success timer" [disabled]="disableNextButton">Next Question</button>
                                    </div>
                                    <div class="col-6 text-center" *ngIf="currentUser && currentUser.data && currentUser.data.quizMaster">
                                      <button (click)="resetGame()" class="btn btn-outline-success timer" [disabled]="disableNextButton">Reset Game</button>
                                  </div>
                              </div>
                              </div>
                            </div>
                        </div>
                        <div class="card" *ngIf="!qMasterSet">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-12 text-center" >
                                        <button (click)="setQuizMaster()" class="btn btn-outline-success timer" >Become Quiz Master & Start Game</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card" *ngIf="qMasterSet">
                          <div class="card-body">
                            <div class="row">
                              <div class="col-md-6 text-center">
                                <button (click)="response(true)" class="btn btn-outline-info timer" [disabled]="responded">I HAVE</button>
                              </div>
                              <div class="col-md-6 text-center">
                                <button (click)="response(false)" class="btn btn-outline-warning timer" [disabled]="responded">I HAVE NOT</button>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                </ng-container>
              </m-card>
            </div>
          </div>
        </section>
      </div>
    </div>
</div>
<div class="app-content content pull-left equal">
    <div class="content-wrapper">
      <div class="content-header row mb-1">
      </div>
      <div class="content-body">
        <section id="drag-area">
          <div class="row">
            <div class="col-md-12" *blockUI="'changelog'; message: 'Loading'">
              <m-card [options]="options" (reloadFunction)="reloadChangelog($event)">
                <ng-container mCardHeaderTitle>
                    <span class="room">You are in room <strong>{{roomName}}</strong></span>
                </ng-container>
                <ng-container mCardBody>
                  <div class="card-text" *ngIf="activeUsers.length>0">
                        <div class="row" *ngFor="let user of activeUsers">
                            <div class="col-6 mb-1">
                                <span class="avatar avatar-online"><img src="{{user.avatarURL}}" >
                                </span>
                                <span class="ml-1">{{user.nickName}}</span>
                                <span *ngIf="currentUser.uid == user.uid" class="ml-1">(me)</span>
                                <span *ngIf="user.quizMaster" class="ml-1 qmaster">(Quiz Master)</span>
                            </div>
                            <div class="col-3">
                              <span *ngIf="user.currentResponse" >
                                <ul ngbNav #nav="ngbNav" class="nav-pills">
                                  <li ngbNavItem class="pill-1" *ngIf="user.currentResponse === 'I HAVE'">
                                    <a ngbNavLink >{{user.currentResponse}}</a>
                                  </li>
                                  <li ngbNavItem class="pill-2" *ngIf="user.currentResponse === 'I HAVE NOT'">
                                    <a ngbNavLink >{{user.currentResponse}}</a>
                                  </li>
                              </ul>                                
                              </span>
                            </div>
                            <div class="col-3 pull-right">
                                <ul ngbNav #nav="ngbNav" class="nav-pills">
                                    <li ngbNavItem>
                                      <a ngbNavLink>{{user.score}}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                  </div>
                </ng-container>
              </m-card>
            </div>
          </div>
        </section>
      </div>
    </div>
</div>