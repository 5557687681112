<div class="app-content content">
  <div class="content-wrapper">
    <div class="content-header row mb-1">
    </div>
    <div class="content-body"><section class="flexbox-container">
<div class="col-12 d-flex align-items-center justify-content-center">
    <div class="col-md-4 col-10 box-shadow-2 p-0">
        <div class="card border-grey border-lighten-3 px-1 py-1 m-0">
            <div class="card-header border-0">
                <div class="card-title text-center">
                    <h5>Work Safe <p>😅</p></h5>
                   <h2>Never Have I Ever</h2>
                   <small>Hand built for the Coeus Squad</small>
                </div>
            </div>
            <div class="card-content">
                <div class="card-body">
                    <form class="form-horizontal" [formGroup]="loginForm" (ngSubmit)="tryLogin()">
                        <fieldset class="form-group position-relative has-icon-left">
                            <input type="text" formControlName="nickName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.nickName.errors }"
                             id="user-name" placeholder="Your Nick name" required>
                            <div class="form-control-position">
                                <i class="feather ft-user"></i>
                            </div>
                            <div *ngIf="submitted && f.nickName.errors" class="invalid-feedback">
                                <div *ngIf="f.nickName.errors.required">Nick name is required</div>
                            </div>
                        </fieldset>
                        <button type="submit" class="btn btn-outline-info btn-block">
                          <i class="fa fa-refresh fa-spin" *ngIf="submitted"></i>
                          <i class="feather ft-unlock" *ngIf="!submitted"></i> Join
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
</section>

    </div>
  </div>
</div>
